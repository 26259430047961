/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.smartTable tr td:not(.ant-table-selection-column) {
  max-width: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.smartTable__upperButtons {
  margin-bottom: 10px; }

.smartTable .ant-btn.single-btn {
  margin-left: 10px; }

.smartTable tr.ant-table-row td:hover {
  white-space: pre-wrap !important;
  cursor: pointer; }

.smartTable tr td:hover input[type='button'],
.smartTable tr td:hover button {
  visibility: visible; }

.smartTable tr.row-no-hover > td:hover {
  cursor: text; }

.smartTable .ant-checkbox-indeterminate .ant-checkbox-inner {
  display: none; }

.smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th {
  text-overflow: ellipsis;
  background-color: #ffffff; }
  .smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th .ant-table-column-sorter .ant-table-column-sorter-inner {
    cursor: pointer;
    font-size: 18px; }

.smartTable .smartTableBody .ant-table-body {
  height: calc(100vh - 284px); }

.smartTable .smartTableNotData .ant-table-empty .ant-table-body {
  height: 0px;
  overflow: hidden; }

.smartTable .ant-empty {
  height: calc(100vh - 360px); }

.smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th {
  background-color: #ffffff; }

.smartTable tr.row-selected {
  background-color: #f1b0b1; }

.smartTable .ant-table-tbody > tr.row-striped:nth-child(even) {
  background-color: #efefef; }

.smartTable .ant-table-thead th {
  white-space: nowrap;
  color: #2b2830; }

.smartTable .ant-table td {
  white-space: nowrap; }

.smartTable .navigation__button,
.smartTable .columns-settings {
  float: right; }

.smartTable th.drop-over-right {
  border-right: 2px dashed #ffffff !important; }

.smartTable th.drop-over-left {
  border-left: 2px dashed #ffffff; }

.smartTable .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-tbody
> tr
td {
  padding: 6px; }

.smartTable > input[type='checkbox'] {
  padding: 2px; }

.smartTable .ant-table-small.ant-table-bordered .ant-table-content {
  height: calc(100vh - 247px); }

.smartTable .ant-table-pagination.ant-pagination {
  margin: 10px 0; }

.smartTable .react-resizable {
  position: relative;
  background-clip: padding-box; }

.smartTable .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1; }

.smartTable .button-disabled {
  cursor: not-allowed;
  color: #a8a8a8; }
