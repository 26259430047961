/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.content,
.contentQuiz {
  overflow: hidden;
  transition: max-height 0.4s ease-in-out; }

.content__on,
.contentQuiz__on,
.showAll__on {
  overflow: hidden;
  max-height: 0px; }

.contentQuiz {
  overflow-y: auto; }

.contentInEdition {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 1100px; }

.collapsible {
  background-color: #ffffff;
  color: #2b2830;
  z-index: 1; }
  .collapsible__translations {
    display: inline-block;
    margin-right: 40px; }
  .collapsible .header {
    font-family: Roboto, sans-serif;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 650;
    height: 65px;
    line-height: 45px; }
    .collapsible .header__titleType {
      display: inline-block;
      width: 150px; }
    .collapsible .header .extraIcon {
      float: left;
      z-index: 999;
      margin-right: 13px; }
      .collapsible .header .extraIcon button {
        height: 20px;
        width: 20px; }
    .collapsible .header .icon {
      color: #2b2830;
      float: right;
      right: 0; }
  .collapsible .collapsibleBody {
    padding: 7px; }
  .collapsible .editableTitle {
    margin-top: 10px;
    height: 32px;
    width: 200px;
    float: left;
    border: none;
    background-color: transparent;
    border-radius: 0px;
    border-bottom: 1px solid #2b2830; }
    .collapsible .editableTitle__buttons--cancel {
      margin-left: 10px; }
  .collapsible .extra {
    padding-top: 10px;
    margin-left: 50px; }
    .collapsible .extra__editable {
      float: left; }
  .collapsible .actionButton {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #2b2830;
    font-size: 17px;
    height: 32px;
    width: 32px;
    z-index: 99; }
  .collapsible .actionButton:hover {
    color: #df3f41; }
  .collapsible .actionButton__disabled {
    background-color: transparent;
    border: none;
    float: right;
    margin-right: 7px;
    font-size: 17px; }
  .collapsible .actionButton__disabled:hover {
    background-color: transparent; }

.ant-row .collapsible__talk {
  cursor: pointer !important;
  width: 100%;
  height: 100%; }
