/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.form__front {
  padding: 6px 0 6px 0; }

.form__checkbox {
  line-height: 16px; }
  @media screen and (min-width: 768px) {
    .form__checkbox {
      padding-top: 20px; } }

.form__checkLabel {
  color: rgba(0, 0, 0, 0.85); }

.form__upperButtons {
  margin-bottom: 10px;
  float: left;
  display: flex; }

.form__divider {
  margin: 8px 0 8px 0; }

.form__bottomButtons {
  display: flex;
  justify-content: flex-end; }
  .form__bottomButtons--floatLeft {
    float: left; }
  .form__bottomButtons--floatRight {
    margin-left: 6px;
    float: right; }

.form .absoluteLeft {
  position: absolute;
  left: 0; }

.form .subgroups {
  background-color: #dfdfdf;
  margin-top: 10px;
  font-weight: bold;
  color: #db5456;
  padding: 1.5px;
  padding-left: 7px; }
  .form .subgroups--box {
    border: 1px solid rgba(167, 167, 167, 0.753);
    margin-bottom: 18px;
    padding: 10px; }
  .form .subgroups--paralel-left {
    padding-left: 12px !important; }
  .form .subgroups--paralel-right {
    padding-right: 12px !important; }
  .form .subgroups--empty {
    padding: 0px; }

.form .ant-tabs .ant-tabs-top-content,
.form .ant-tabs .ant-tabs-bottom-content {
  min-height: 215px;
  overflow-y: auto;
  height: calc(100vh - 287px); }

.form .ant-input-number-focused,
.form .ant-input:not([disabled]):focus,
.form .ant-select-selection:not([disabled]):focus,
.form .ant-input-number:not([disabled]):focus {
  background-color: #ccd7ff; }

.form .ant-input-number,
.form .ant-time-picker,
.form .ant-calendar-picker {
  width: 100%; }

.form .anticon-close-circle {
  color: rgba(0, 0, 0, 0.25); }
  .form .anticon-close-circle :hover {
    color: rgba(0, 0, 0, 0.5); }

.ant-tabs-nav-wrap {
  padding-top: 8px;
  padding-bottom: 8px; }

.formDropdown {
  background-color: #ffffff;
  width: 600px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  padding: 12px !important; }
  @media screen and (max-width: 992px) {
    .formDropdown {
      width: 100%; } }

.dropdownItem {
  padding-right: 0px !important; }
  .dropdownItem .ant-select-selection {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; }

.dropdownCustom {
  padding: 0 0 0 0 !important;
  padding-right: -10px !important;
  margin: 35px 0 0; }
  .dropdownCustom button {
    width: 15px;
    margin: 0;
    padding: 0;
    padding-right: 10px;
    border: none;
    background-color: transparent; }
  .dropdownCustom .ant-btn-icon-only {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  @media screen and (max-width: 768px) {
    .dropdownCustom {
      margin-top: 0px;
      text-align: left; } }

.ant-calendar-picker {
  width: 100%; }

.editorClassName {
  border: 1px solid #f1f1f1; }
