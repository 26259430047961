/*
*  COLORS
*/
/**
*Type
*/
/*
*  BREAKPOINTS
*/
/*
*  IMG
*/
/*
*  FONT SIZES
*/
.pageForm {
  margin: 20px;
  margin-bottom: 0; }
  .pageForm__container {
    margin-top: 20px !important; }

.PageContent__headerButtons {
  float: right; }
  .PageContent__headerButtons--save, .PageContent__headerButtons--restore {
    margin-left: 20px; }

.Component__collapse {
  border: 1px solid #d8d8d8;
  border-top: none; }

.Component__bottomButtons {
  margin: 20px; }

.Component__iconSelector {
  padding-top: 12px;
  display: flex;
  width: 300px;
  float: left;
  margin-left: 70px; }
  .Component__iconSelector--title {
    text-transform: uppercase;
    float: left;
    margin-right: 20px; }

.Component__modalSelector {
  float: left;
  width: 200px; }

.Component__uploadRow {
  margin-top: -4px; }
  .Component__uploadRow .ant-input-number {
    width: 50px; }
  .Component__uploadRow--linked-validation {
    color: grey;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px; }

.Component__faq-item .ant-input-number {
  width: 50px; }

.Component__checkbox-autoplay {
  transform: scale(1.4); }
